import { render, staticRenderFns } from "./SortChanger.vue?vue&type=template&id=04856365&scoped=true&lang=pug&"
import script from "./SortChanger.vue?vue&type=script&lang=js&"
export * from "./SortChanger.vue?vue&type=script&lang=js&"
import style0 from "./SortChanger.vue?vue&type=style&index=0&id=04856365&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04856365",
  null
  
)

export default component.exports