<template lang="pug">
b-dropdown.sort-menu(aria-role="list" position="is-bottom-left")
  a.trigger(slot='trigger')
    b-icon(icon="sort-alpha-up")
  b-dropdown-item(@click="setSortMode('alpha')")
    span(:class='{chosen: categoryOrder === "alpha"}') Alphabetical
  b-dropdown-item(@click="setSortMode('size')")
    span(:class='{chosen: categoryOrder === "size"}') Largest first
  b-dropdown-item(@click="setSortMode('user')")
    span(:class='{chosen: categoryOrder === "user"}') Custom
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('authentication', ['categoryOrder'])
  },
  methods: {
    ...mapActions('authentication', ['updatePreferences']),
    setSortMode(mode) {
      this.updatePreferences({ categoryOrder: mode })
    }
  }
}
</script>

<style lang="sass" scoped>
.sort-menu
  margin: 0 0.75rem
  .chosen
    font-weight: bold
  .trigger
    color: #555
    &:hover
      color: #333
</style>
